
export interface BookingSessionProps {
    sessionName: string;
    price: string;
    additionalInfo?: string[]
    onClickAction: () => {}
}


const defaultAdditionalInfoList = () => {
    return (
        <>
            <p className="text-gray-700 text-sm font-medium mb-2">Ideal for photographers</p>
            <p className="text-gray-700 text-sm font-medium mb-2"> Up to 10 people<span className="text-red-500 font-bold px-1">*</span> </p>
            <p className="text-gray-700 text-sm font-medium mb-2">$11 per additional guest<span className="text-red-500 font-bold px-1">*</span> </p>
            <p className="text-gray-700 text-xs font-medium mb-2 mt-5"><span className="text-red-500 font-bold">*</span> does not apply to photographers</p>
        </>
    );
};

export default function SessionPricingDisplay(props: BookingSessionProps) {
    const { sessionName, price, additionalInfo, onClickAction } = props;
    return (
        <div className="flex flex-col justify-between justify-center bg-white rounded-lg shadow-md p-6 m-5">
            <h2 className="text-2xl font-bold mb-4">{sessionName}</h2>
            <div>
                {additionalInfo ? additionalInfo.map((info, index) => <p key={index} className="text-gray-700 text-sm font-medium mb-2">{info}</p>) : defaultAdditionalInfoList()}
            </div>
            <div>
                <p className="text-gray-700 text-xl font-medium mb-4">${price}</p>
                <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full" onClick={onClickAction}>
                    Book now
                </button>
            </div>
        </div>
    );
};
