import React, {useEffect} from 'react';
import './App.css';
import Header from "components/Header";
import Footer from "components/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Gallery from "pages/gallery";
import HowItWorks from "pages/howItWorks";
import PrivateBooking from "./pages/privateBooking";
import SpecialEvents from "./pages/specialEvents";

function App() {
    useEffect(() => {
        document.title = "Social Studio 417"
    }, []);
  return (
      <>
        <main className="bg-white lg:px-5 min-h-screen flex flex-col justify-between">
            <div>
                <BrowserRouter>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Gallery />} />
                        <Route path="/howItWorks" element={<HowItWorks />} />
                        <Route path="/privateBooking" element={<PrivateBooking />} />
                        <Route path="/specialEvents" element={<SpecialEvents />} />
                    </Routes>
                </BrowserRouter>
            </div>
            <Footer />
        </main>
      </>
  );
}

export default App;
