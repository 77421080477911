import NavItem from "./NavItem";
import {FaFacebook, FaInstagram} from "react-icons/fa";
import {RxCross2, RxHamburgerMenu} from "react-icons/rx";
import React, {useState} from "react";
import {CommonButton, CommonButtonRoles} from "components/Common/Button";
import {NavOptions} from "Core/NavOptions";

interface INavBarProps {
    buttonTitle: string;
    buttonOnClick: () => {}
}

export default function NavBar(props: INavBarProps) {
    const { buttonTitle, buttonOnClick } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <section>
            <nav className="hidden lg:flex justify-between py-5 px-10 w-auto font-poppins items-baseline">
                <div className="flex items-baseline">
                    <a href="/"><h1 className="text-2xl mr-2">The Social Studio</h1></a>
                    <a href="https://www.facebook.com/TheSocialStudio417" className="ml-2 mt-1 text-blue-500 text-2xl hidden xl:flex"><FaFacebook /></a>
                    <a href="https://www.instagram.com/thesocialstudio417" className="ml-2 mt-1 text-pink-500 text-2xl hidden xl:flex"><FaInstagram /></a>
                </div>
                <div className="flex flex-row h-12">
                    <ul className="flex justify-evenly items-baseline pt-2 pr-5">
                        { NavOptions.map((item, index) => ( <NavItem path={item.path} key={index} title={item.title} />)) }
                    </ul>
                    <CommonButton role={CommonButtonRoles.Default} onClickAction={buttonOnClick} title={buttonTitle} />
                </div>
            </nav>
            <nav className="flex lg:hidden min-w-screen p-5 border-b-2">
                <div className="flex justify-between w-full">
                    <a href="/"><h1 className="text-lg mr-2 mt-1">The Social Studio</h1></a>
                    <div className="flex w-1/2 justify-end gap-5">
                        <CommonButton role={CommonButtonRoles.Default} onClickAction={buttonOnClick} title={buttonTitle} />
                        <RxHamburgerMenu className={`text-4xl ${isExpanded ? 'hidden' : ''}`} onClick={() => setIsExpanded(true)}/>
                        <RxCross2 className={`text-4xl ${isExpanded ? '': 'hidden'}`} onClick={() => setIsExpanded(false)} />
                    </div>
                </div>
            </nav>
            <div className={`flex w-full ${isExpanded ? "flex" : "hidden"}`}>
                <ul className="w-full">
                    { NavOptions.map((item, index) => ( <NavItem path={item.path} key={index} title={item.title} onClickAction={() => setIsExpanded(false)} />)) }
                </ul>
            </div>
        </section>
    );
}
