import FAQs from 'components/FAQs';
import InfoBlock from 'components/InfoBlock';
import ByobRules from "components/ByobRules";
import Pricing from "components/Pricing";

type Info = {
    title: string;
    text: string[];
    disclaimer?: string;
}
const items: Info[] = [
    {
        title: "How does it work?",
        text: [
            "Buy your tickets or make a reservation online.",
            "Use your phone, camera, picture taking device or bring a photographer. We have ring lights, selfie sticks, tripods and fun signs and props!",
            "Guaranteed 10+ photo experiences and set ups!",
            "Take as many photos as you like and you keep them all!"
        ],
        disclaimer: "Anyone under the age of 16 must be accompanied by an adult"
    }
];

export default function HowItWorks() {
    return (
        <section className="flex flex-col flex-wrap w-full">
            { items.map((item, index) => ( <InfoBlock title={item.title} text={item.text} key={index} disclaimer={item.disclaimer} />)) }
            <Pricing />
            <ByobRules />
            <FAQs />
        </section>
    )
}
