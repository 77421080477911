export enum TimeOfDay {
    Morning= "Morning",
    Afternoon = "Afternoon",
    Evening = "Evening"
}

export type BookingData = {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    visitorCount: number;
    moreInfo: string;
    date: string;
    timeOfDay?: string
    sessionName?: string;
}
