
export default function Pricing() {
    return (
        <section className="flex flex-col py-5 px-10">
            <h3 className="flex text-2xl pb-2 w-full">Pricing</h3>
            <p className="text-md">Standard Ticket (all ages): $18.50 per person</p>

            <p className="text-md">4+ Ticket Bundle: $16.65 per person</p>

            <p className="italic text-md"> purchase 4 or more tickets on one transaction for discount</p>
        </section>
    );
};
