type Faq = {
    question: string;
    answer: string;
};

const faqData: Faq[] = [
    {
        question: "Does everyone need a ticket?",
        answer: "Everyone entering the studio must have a ticket, except non-walking babies."
    },
    {
        question:"How many people are allowed in the studio at one time?",
        answer:"6 guests during one 30-minute session without a private reservation."
    },
    {
        question:"Do I need to do a private booking?",
        answer:"If you have a group of 6 or more people, want a longer session, or just want the studio privately, then yes!"
    },
    {
        question: "What about professional photos?",
        answer: "Photographers are more than welcome! They just need a ticket for themselves."
    },
    {
        question: "Does my baby need a ticket?",
        answer: "Only non-walking babies are free! All other children need a ticket."
    },
    {
        question: "What can I bring to help us celebrate?",
        answer: "Signs, Balloons, Banners, etc. are welcome! Small smash cakes and BYOB are allowed with a private booking only. We will check everyone's ID when you arrive."
    },
    {
        question: "Do you offer refunds?",
        answer: "We cannot offer refunds. We understand that things happen and you may reschedule if we are notified by text 2 hours in advance of your reservation time."
    },
    {
        question: "Do I have to book and appointment or can I walk in?",
        answer: "We only accept reservations currently due to limited space."
    },
    {
        question: "Do you offer any discounts?",
        answer: "If you purchase 4+ tickets on one transaction, you will receive a discount off of each ticket. If you need 6+ tickets for your group, you will need to book a private reservation. A private reservation will save you money when purchasing 6 or more tickets."
    }
];


const FaqItem = (item: Faq) => {
    return (
        <li className="flex flex-wrap mt-2 w-full">
            <p className="flex w-full font-bold text-md"> {item.question}</p>
            <p className="flex w-full pl-2"> {item.answer}</p>
        </li>
    );
};
export default function FAQs() {
    return (
        <section className="flex flex-col py-5 px-10">
          <h3 className="flex text-2xl pt-5 pb-2 w-full">Frequently Asked Questions</h3>
          <ul className="flex flex-wrap">
              {faqData.map((item, index) => <FaqItem question={item.question} answer={item.answer} key={index} />)}
          </ul>
        </section>
    );
}

