import React from "react";

type InputType = string | number | any;
interface FormFieldProps {
    fieldName: string;
    placeholder: string;
    fieldLabel: string;
    required: boolean;
    isValid: () => boolean;
    handleChange: (event:any) => void;
    value: InputType;
    type?: string;
    errMsg?: string;

}
export default function FormField(props: FormFieldProps) {
    const { fieldName, fieldLabel, placeholder, isValid, required, value, handleChange, type, errMsg } = props;
    const inputType = type ?? "text";
    const errorMsg = errMsg ?? "Empty/invalid value."
    return (
        <div className="pb-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={fieldName}>
                {fieldLabel}
            </label>
            <input className={`appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${required && isValid() ? 'border border-gray-200 focus:border-gray-500' : 'border border-red-500 focus:border-2'}`} id={fieldName} name={fieldName} type={inputType} placeholder={placeholder} value={value} onChange={handleChange}/>
            <p className={`text-red-500 text-xs italic ${required && isValid() ? 'invisible' : 'visible'}`}>{errorMsg}</p>
        </div>
    );
};
