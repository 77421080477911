import React, {useState} from "react";
import SpecialEventDialog from "./SpecialEventDialog";
import {SessionInfo} from "./SessionInfo";

type SpecialEvent = {
    imagePath: string;
    title: string;
    availableSlots: Date[];
    availableSessions: SessionInfo[];
    useGranularTimes: boolean;
    pricingFn: (numGuests: number, price: number) => number;
}

const SpecialEvents: SpecialEvent[] = [
    {
        imagePath: "/bibbidi_bobbidi_princess_social.jpg",
        title: "Bibbidi Bobbidi Princess Social",
        availableSessions: [
            {
                sessionLength: "Standard",
                price: "45"
            }
        ],
        availableSlots: [
            //May 12th
            new Date(2023, 4, 12, 17, 0),
            new Date(2023, 4, 12, 17, 15),
            new Date(2023, 4, 12, 17, 30),
            new Date(2023, 4, 12, 17, 45),
            new Date(2023, 4, 12, 18, 0),
            new Date(2023, 4, 12, 18, 15),
            new Date(2023, 4, 12, 18, 30),
            new Date(2023, 4, 12, 18, 45),
            // May 13th
            new Date(2023, 4, 13, 13, 0),
            new Date(2023, 4, 13, 13, 15),
            new Date(2023, 4, 13, 13, 30),
            new Date(2023, 4, 13, 13, 45),
            new Date(2023, 4, 13, 14, 0),
            new Date(2023, 4, 13, 14, 15),
            new Date(2023, 4, 13, 14, 30),
            new Date(2023, 4, 13, 14, 45),
        ],
        useGranularTimes: true,
        pricingFn: (numGuests: number, price: number) => {
            let calculatedPrice = price;
            if (numGuests > 1) {
                calculatedPrice = price + (40 * (numGuests - 1));
            }
            return Math.max(calculatedPrice, 0)
        }
    }
];


export default function SpecialEventInfo() {
    const [isOpen, setIsOpen] = useState(false);
    const [specialEventInfo, setSpecialEventInfo] = useState<SpecialEvent>(
        {
            imagePath: "",
            title: "",
            availableSlots: [],
            availableSessions: [],
            useGranularTimes: false,
            pricingFn : () => {return 0}
        }
    );

    const handleClick = (item: SpecialEvent) => {
        setSpecialEventInfo(item);
        setIsOpen(true);
    };


    return (
        <div className="w-full flex flex-wrap">
            <div className="w-full flex flex-col">
                <div className="flex flex-wrap justify-start">
                    {SpecialEvents.map((item, index) =>
                        (
                            <div className="flex flex-col items-center w-full md:w-auto h-full p-3 shadow-sm shadow-slate-500 hover:shadow-slate-600 hover:shadow-md cursor-pointer" key={index} onClick={() => {handleClick(item)}}>
                                <div className="flex object-contain w-full md:w-96">
                                    <img src={item.imagePath} alt={item.title} />
                                </div>
                                <div className="flex flex-col h-full w-1/2 items-center text-center p-2">
                                    <h3 className="flex text-md pb-2">{item.title}</h3>
                                    <div className="flex flex-col text-sm pb-2 w-full justify-evenly">
                                        {item.availableSessions.map((session, index) => (<h4 key={index}>{session.sessionLength} - ${session.price}</h4>))}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <section className="py-10">
                <SpecialEventDialog isOpen={isOpen} setIsOpen={setIsOpen} title={specialEventInfo.title} availableSessions={specialEventInfo.availableSessions} availableSlots={specialEventInfo.availableSlots} useGranularTimes={specialEventInfo.useGranularTimes} pricingFn={specialEventInfo.pricingFn} />
            </section>
        </div>
    );
}
