import {FaFacebook, FaInstagram} from "react-icons/fa";

export default function Footer() {
    return (
        <footer className="flex w-full px-10 py-5 border border-t-slate-200 border-t-1 border-l-0 border-r-0 border-b-0 justify-between">
            <div className="min-w-24 flex flex-col">
                <div className="flex justify-between">
                    <h3 className="text-lg pb-2">The Social Studio</h3>
                </div>
                <div className="py-2">
                    <p className="text-sm"> 141 W St Hwy 174</p>
                    <p className="text-sm"> Republic, MO 65738</p>
                    <p className="text-sm"> Across from Casey&apos;s</p>
                    <p className="text-sm">Call or text: (417) 319-6505</p>
                </div>
                <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://goo.gl/maps/Cjq6T7HsrBMF7D3S9">Directions</a>
            </div>
            <div className="min-w-24 flex">
                <a href="https://www.facebook.com/TheSocialStudio417" className="ml-1 mt-1 text-slade-300 text-3xl"><FaFacebook /></a>
                <a href="https://www.instagram.com/thesocialstudio417" className="ml-1 mt-1 text-slade-300 text-3xl"><FaInstagram /></a>
            </div>

        </footer>
    );
}
