import React from "react";
import {PrivateBookingInfo} from "components/PrivateBooking";

const PrivateBooking = () => {
    return (
        <section className="flex w-full px-10 py-5">
            <PrivateBookingInfo />
        </section>
    );
}

export default PrivateBooking;
