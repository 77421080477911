
interface IInfoBlockProps {
    title: string;
    text: string[];
    disclaimer?: string;
};

export default function InfoBlock(props: IInfoBlockProps) {
  const { title, text, disclaimer } = props;

  return (
    <section className="flex flex-wrap w-full p-10 m-auto">
        <h2 className="flex text-2xl pt-5 pb-2 w-full">{title}</h2>
        {text.map((item, index) => (<p className="flex text-md w-full" key={index}> {item}</p>))}
        {disclaimer ? <h3 className="flex text-md pt-5 font-bold w-full"> {disclaimer}</h3> : null }
    </section>
  );
}
