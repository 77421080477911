import {BookingData} from "Core/BookingData";
import BaseUrl from "./BaseUrl";

export interface ISpecialEventService {
    post: (data: BookingData) => Promise<any>
}
export default class SpecialEventService implements ISpecialEventService {
    async post(data: BookingData): Promise<any> {
        const endpoint = `${BaseUrl()}/specialEvents`;

        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify(data),
        }

        const response = await fetch(endpoint, options)

        return await response.json();
    }
}
