import React from "react";

export enum CommonButtonRoles {
    Default,
    CallToAction
}
export interface CommonButtonProps {
    role: CommonButtonRoles;
    onClickAction: () => {}
    title: string;
}

const roleClassList = (role: CommonButtonRoles) => {
    const commonClasses = "text-xs font-semibold py-2 px-4 border border-2 border-slate-500 rounded lg:text-lg";
    const callToActionClasses = "text-white bg-slate-700 hover:text-slate-700 hover:bg-transparent";
    const defaultClasses = "hover:bg-slate-700 text-slate-700 hover:text-white";
    const compiledClassList = commonClasses + " " + (role === CommonButtonRoles.CallToAction ? callToActionClasses : defaultClasses);
    return compiledClassList;
};
export const CommonButton: React.FC<CommonButtonProps> =  ({role, onClickAction, title}) => {
    return (
        <button className={roleClassList(role)} onClick={onClickAction} >{title}</button>
    );

}
