import {ImageGallery} from "components/Images/Gallery";
import {GalleryImage} from "components/Images/contracts";

const sources: string[] = [
    "/social_studio_main.jpeg",
    "/gallery_1.png",
    "/gallery_2.png",
    "/gallery_3.png",
    "/gallery_4.png",
    "/gallery_5.png",
    "/gallery_6.png"
];
const images: GalleryImage[] = sources.map((source, index) => {
    const displayId = index+1;
    return {
        source,
        displayId,
        alt: `Image ${displayId}`
    };
});

export default function Gallery() {
    return (
        <section className="flex w-full justify-center px-10 py-5">
            <ImageGallery images={images} />
        </section>
    );
}


