import React, {useState} from 'react';
import {GalleryImage} from "./contracts";
import {Dialog} from "@headlessui/react";
import {AiOutlineEye} from "react-icons/ai";

type GalleryItemProps = {
    image: GalleryImage;
};

export const GalleryItem = ({ image }: GalleryItemProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="relative group">
                <img className="w-full h-60 object-cover rounded-lg shadow-lg cursor-pointer transition duration-300 transform hover:scale-105 z-50" src={image.source} alt={image.alt} onClick={openModal} />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300 bg-black bg-opacity-50 rounded-lg cursor-pointer" onClick={openModal}>
                    <AiOutlineEye className="w-10 h-10 text-white transition duration-300 transform hover:scale-110" />
                </div>
            </div>
            <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 overflow-y-auto" >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-20 z-40" />
                    <img className="inline-block max-h-screen mx-auto my-8" src={image.source} alt={image.alt} />
                </div>
            </Dialog>
        </>
    );
};
