import React from "react";
import {Link, useLocation} from "react-router-dom";
import {RiCameraLensFill} from "react-icons/ri";

interface INavItemProps {
    path: string;
    key: number;
    title: string;
    onClickAction?: (() => {}) | (() => void);
}

export default function NavItem(props: INavItemProps) {
    const { path, title, onClickAction } = props;

    const location = useLocation();
    return (
        <li className={`flex w-full lg:w-auto p-2 border border-t-0 border-slate-200 ${location.pathname === path ?
                "lg:border-l-0 lg:border-r-0 lg:border-b-2 lg:font-normal" : "lg:border-0"}`} >
            <RiCameraLensFill className={`flex lg:hidden text-xl px-5 ${location.pathname === path ? 'visible' : 'invisible'}`}/>
            <Link className="px-5 lg:px-1" onClick={onClickAction} to={path}>{title}</Link>
        </li>
    );
}


