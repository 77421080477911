import {GalleryItem} from "./GalleryItem";
import {GalleryProps} from "./contracts";

export const ImageGallery = ({ images }: GalleryProps) => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {images.map((image, index) => (
                <GalleryItem key={index} image={image} />
            ))}
        </div>
    );
};


