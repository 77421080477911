import {BookingData} from "Core/BookingData";
import BaseUrl from "./BaseUrl";

export interface IAppointmentService {
    post: (data: BookingData) => Promise<boolean>
}
export default class AppointmentService implements IAppointmentService{
    async post(data: BookingData):  Promise<any> {
            const endpoint = `${BaseUrl()}/appointments`;

            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                body: JSON.stringify(data),
            }

            const response = await fetch(endpoint, options)
            return await response.json();
    }

}
