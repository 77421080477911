type NavOption = {
    title: string,
    path: string
}

export const NavOptions: NavOption[] = [
    {title: "Gallery", path: "/"},
    {title: "Private reservation", path: "/privateBooking"},
    {title: "How it works", path: "/howItWorks"},
    {title: "Special Events", path: "/specialEvents"}
];
