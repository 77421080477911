export default function ByobRules() {
    return (
        <section className="flex flex-col py-5 px-10">
            <h3 className="flex text-2xl pb-2 w-full">BYOB Rules</h3>
            <ol className="flex flex-col flex-wrap list-decimal px-5">
                <li>Must be 21 or over. You must present a valid ID</li>
                <li>Only allowed with a private booking</li>
                <li>Only clear or light-colored liquids. We need to keep those set-ups stain-free!</li>
                <li>Be considerate of others and respect the studio space.</li>
            </ol>
        </section>
    );
}
