import React, {useState} from "react";
import {TimeOfDay} from "Core/BookingData";
import {CommonButton, CommonButtonRoles} from "components/Common/Button";
import AppointmentService, {IAppointmentService} from "services/AppointmentService";
import FormField from "components/FormField";


interface AppointmentFormProps {
    service?: IAppointmentService;
    onClose: () => void
}


export default function AppointmentForm(props: AppointmentFormProps) {
    const { service, onClose } = props;
    const timesOfDay = Object.values(TimeOfDay);
    const serviceInitialized = service ?? new AppointmentService();
    const [errorMessage, setErrorMessage] = useState("");

    const [formData, setFormData] = useState({
        firstName:"",
        lastName: "",
        phone: "",
        email:"",
        visitorCount: 1,
        moreInfo: "",
        date: "",
        timeOfDay: "Choose one",
        sessionName: "appointment"
    });

    const todayDisplay = new Date(Date.now()).toLocaleDateString();

    const handleSubmit = async () => {
        const result = await serviceInitialized.post(formData);

        if (result.error) {
            setErrorMessage(result.error);
            return;
        }

        setErrorMessage("");
        alert("Your appointment has been submitted. We will contact you within the next business day.");
        onClose();
    }
    const totalCost = () => {
        var ticketCost = formData.visitorCount >= 4 ? 16.65 : 18.50;
        return formData.visitorCount * ticketCost;
    };


    const handleChange = (event: any) => {
        let newValue = event.target.value;
        if (event.target.name === 'phone') {
            newValue = formatPhoneNumber(newValue);
        }
        setFormData({...formData, [event.target.name]: newValue});
    };
    const formatPhoneNumber = (str: string) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };

    const isValidPhoneNumber = () => {
        const regex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
        return regex.test(formData.phone);
    };

    const isValidDate = () => {
        const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        return regex.test(formData.date);
    };

    const isValidTimeOfDay = () => {
        return formData.timeOfDay !== "Choose one";
    };

    const isValidEmail = () => {
        // eslint-disable-next-line no-control-regex
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return regex.test(formData.email);
    };

    // @ts-ignore
    return (
        <form className="flex flex-col w-full max-w-lg px-5 pt-5 max-w-lg overflow-y-auto">
            <div className={`w-full mx-3 mb-6 ${errorMessage.length !== 0 ? 'text-red-500 flex' : 'hidden'}`}>
                <h3>{errorMessage}</h3>
            </div>
            <div className="flex flex-wrap mx-3">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <FormField fieldName="firstName" placeholder="Jane" fieldLabel="First Name" required={true} isValid={() => formData.firstName.length > 1 } handleChange={handleChange} value={formData.firstName} />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <FormField fieldName="lastName" placeholder="Doe" fieldLabel="Last Name" required={true} isValid={() => formData.lastName.length > 1} handleChange={handleChange} value={formData.lastName} />
                </div>
            </div>
            <div className="flex flex-wrap mx-3">
                <div className="w-full px-3">
                    <FormField fieldName="phone" placeholder="417-555-2385" fieldLabel="Phone" required={true} isValid={isValidPhoneNumber} handleChange={handleChange} value={formData.phone} />
                </div>
                <div className="w-full px-3">
                    <FormField fieldName="email" placeholder="jane.doe@gmail.com" fieldLabel="Email" required={true} isValid={isValidEmail} handleChange={handleChange} value={formData.email} />
                </div>
                <div className="w-full px-3">
                    <FormField fieldName="visitorCount" placeholder="4" fieldLabel="How many people?" required={true} isValid={() => formData.visitorCount > 0 && formData.visitorCount < 100} handleChange={handleChange} value={formData.visitorCount} type="number"/>
                </div>
            </div>
            <div className="flex flex-wrap mx-3">
                <div className="w-full md:w-1/2 px-3">
                    <FormField fieldName="date" placeholder={todayDisplay} fieldLabel="Date" required={true} isValid={isValidDate} handleChange={handleChange} value={formData.date} errMsg="MM/DD/YYYY format" />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="timeOfDay">
                        Time of Day
                    </label>
                    <select value={formData.timeOfDay} onChange={handleChange} className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-3 ${!isValidTimeOfDay() ? 'border border-red-500 focus:border-2' : ''}`} name="timeOfDay" placeholder="Choose one">
                        <option value="Choose one">Choose one</option>
                        {timesOfDay.map((item, index) => <option value={item} key={index}> {item}</option>) }
                    </select>
                    <p className={`text-red-500 text-xs italic ${isValidTimeOfDay() ? 'invisible' : 'visible'}`}>Select a time of day</p>
                </div>
            </div>

            <div className="flex flex-wrap mx-3 ">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="moreInfo">
                        More Info
                    </label>
                    <textarea className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="moreInfo" name="moreInfo" rows={5} placeholder="Please fill out any details including date, time, occasion, etc." value={formData.moreInfo} maxLength={400} onChange={handleChange} />
                    <p className="text-gray-600 text-xs italic"></p>
                </div>
            </div>
            <div className="w-full p-5 flex justify-between">
                <div className="flex flex-col">
                    <h4 className="text-sm text-gray-700 font-bold pb-2">{formData.visitorCount} {formData.visitorCount > 1 ? "tickets" : "ticket" } </h4>
                    <h3 className="text-xl text-black font-bold">${totalCost().toFixed(2)}</h3>
                </div>
                <CommonButton role={CommonButtonRoles.CallToAction} onClickAction={handleSubmit} title="Submit" />
            </div>
        </form>
    );
}
