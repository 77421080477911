import React, {useState} from "react";
import AppointmentDialog from "./AppointmentDialog";
import NavBar from "./NavBar";

export default function Header() {

    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <NavBar buttonTitle="Book Now" buttonOnClick={async () => setIsOpen(true)}/>
            <AppointmentDialog isOpen={isOpen} setIsOpen={setIsOpen} />

        </>
    );


}
