import SessionPricingDisplay from "./SessionPricingDisplay";
import React, {useState} from "react";
import PrivateBookingDialog from "./PrivateBookingDialog";

type BookingInfo = {
    title: string;
    text: string;
    disclaimer?: string;
}

const BookingInfoItem = (props: BookingInfo) => {
    const {title, text, disclaimer} = props;
    return (
        <div className="pb-10">
            <h3 className="text-xl pb-2">{title}</h3>
            <p className="text-md">{text}</p>
            {disclaimer ? <h3 className="text-md pt-5 font-bold"> {disclaimer}</h3> : null}
        </div>
    );
};
const BookingInfoItems: BookingInfo[] = [
    {
        title: "Food and drinks",
        text: "We do allow BYOB for private rentals. Please review the BYOB rules before making your reservation. If bringing food or drinks, we do ask you to run it by us first. We allow cake and clear/light colored liquids (alcoholic or non-alcoholic). We do not charge a clean up fee, but please be considerate and clean up any mess. Please notify us of any spills involving set-ups so we can quickly assist.",
        disclaimer: "If any alcohol is brought in, we will need to see an ID from each member of the group."
    }
];


type BookingSession = {
    sessionName: string;
    price: string;

    additionalInfo?: string[]
}

const BookingSessions: BookingSession[] = [
    {
        sessionName: "45 Minutes",
        additionalInfo: [
            "Up to 10 people",
            "$11 per additional guest ticket",
            "Saves you money when purchasing tickets for more than 6 people",
            "Ideal for groups wanting guaranteed privacy for their session"
        ],
        price: "110"
    },
    {
        sessionName: "1 Hour",
        price: "125"
    },
    {
        sessionName: "1.5 Hours",
        price: "145"
    },
    {
        sessionName: "2 Hours",
        price: "165"
    },
    {
        sessionName: "3 Hours",
        price: "200"
    },
    {
        sessionName: "4 Hours",
        price: "225"
    },
    {
        sessionName: "6 Session Bundle",
        price: "92.50",
        additionalInfo: [
            "Six 30-minute sessions for 1 person",
            "$9.50 per additional ticket per session",
            "Ideal for business purposes like product photography, social media content, reels, etc."
        ]
    }
];

export default function PrivateBookingInfo() {
    const [isOpen, setIsOpen] = useState(false);
    const [privateSessionInfo, setPrivateSessionInfo] = useState({
        sessionName: "",
        initialCost: 0
    });


    const onClickAction = async (item: BookingSession) => {
        setIsOpen(true);
        const convertedCost: number = +item.price;
        setPrivateSessionInfo({sessionName: item.sessionName, initialCost: convertedCost})
    };

    return (
        <div className="w-full flex flex-wrap justify-center">
            <div className="flex flex-col py-12 bg-gray-100 mb-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <h2 className="text-base text-purple-600 font-semibold tracking-wide uppercase">
                            Private bookings
                        </h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Book your own private photo experience
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                            Perfect for families, influencers, and photographers. Choose the
                            perfect package for your needs.
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                            To make sure we can secure your date it is best to book at least 3 weeks in advance.
                        </p>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {BookingSessions.map((item, index) => (
                            <SessionPricingDisplay sessionName={item.sessionName} price={item.price}
                                                   additionalInfo={item.additionalInfo}
                                                   key={index} onClickAction={async () => {
                                await onClickAction(item)
                            }}/>))}
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col gap-4">
                {BookingInfoItems.map((item, index) => (
                    <BookingInfoItem title={item.title} text={item.text} disclaimer={item.disclaimer} key={index}/>))}
            </div>

            <section className="py-10">
                <PrivateBookingDialog isOpen={isOpen} setIsOpen={setIsOpen} sessionName={privateSessionInfo.sessionName}
                                      initialCost={privateSessionInfo.initialCost}/>
            </section>
        </div>
    );
}
