import React from "react";
import {SpecialEventInfo} from "../components/SpecialEvent";

export default function SpecialEvents() {
    return (
        <section className="flex w-full px-10 py-5">
            <SpecialEventInfo />
        </section>
    );
}
